<script>
export default {
  methods: {
    component (type) {
      const types = [
        'address',
        'bdoLogic',
        'boolean',
        'containerId',
        'date',
        'defaultDebrisTypeCode',
        'netGrossPrice',
        'paidValue',
        'paymentType',
        'price',
        'selectDebrisType',
        'selectFromFilters',
        'selectFromItems',
        'textArea',
        'textField',
        'timeSlot',
        'unitPrice',
      ]
      const componentName = type[0].toUpperCase() + type.slice(1)

      if (!this.$options.components[componentName]) {
        if (types.includes(type)) {
          this.$options.components[componentName] = () =>
            import(`@/components/Forms/Inputs/${componentName}.vue`)
        } else {
          this.$options.components[componentName] = () =>
            import('@/components/Forms/Inputs/TextField.vue')
        }
      }
      return componentName
    }
  }
}
</script>

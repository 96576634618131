<template>
  <DialogFormWrapper :hide-actions="!field.editable">
    <template #form>
      <v-form
        id="updateDialogForm"
        ref="updateOrderForm"
        class="custom-form pt-8"
        @submit.prevent="updateValue()"
      >
        <v-row>
          <v-col cols="12">
            <component
              :is="component(field.type)"
              :field="field"
              :data="dialog.item"
              @update="setUpdatedParams"
            />
          </v-col>
        </v-row>
      </v-form>
    </template>
    <template
      #submit
      v-if="field.editable"
    >
      <v-btn
        color="primary"
        class="base-hover"
        :loading="isProcessing"
        :disabled="!editingItemId"
        type="submit"
        form="updateDialogForm"
      >
        Zapisz zmianę
      </v-btn>
    </template>
  </DialogFormWrapper>
</template>

<script>
import rules from '../../utils/validators'
import DialogFormWrapper from './Partials/DialogFormWrapper'
import dynamicComponentMixin from '../../mixins/DynamicComponentMixin'
import { mapState, mapActions } from 'vuex'
import get from 'lodash/get'

export default {
  components: {
    DialogFormWrapper
  },
  mixins: [dynamicComponentMixin],
  data () {
    return {
      rules,
      params: null
    }
  },
  computed: {
    ...mapState({
      dialog: state => state.layout.dialog.data,
      field: state => state.layout.dialog.data.field,
      isProcessing (state) {
        return state[this.dialog.tableName].isProcessing
      },
    }),
    editingItemId () {
      const { item, idPath } = this.dialog
      return get(item, idPath)
    }
  },
  methods: {
    ...mapActions({
      closeDialog: 'layout/closeDialog',
      editItem (dispatch, payload) {
        return dispatch(`${this.dialog.tableName}/editItem`, payload)
      },
      getItems (dispatch, tableName) {
        return dispatch(`${tableName}/getItems`)
      },
    }),
    setUpdatedParams (params) {
      this.params = params
    },
    updateValue () {
      if (this.$refs.updateOrderForm.validate()) {
        const { endpoint, notWebSocketHandled } = this.dialog
        this.editItem({
          tableName: endpoint,
          params: this.params,
          notWebSocketHandled,
          id: this.editingItemId
        }).then(() => {
          const { refreshTables } = this.field
          if (refreshTables) refreshTables.forEach(table => this.getItems(table))
          this.closeDialog()
        })
      }
    }
  }
}
</script>
